import axios from 'axios'

const token = localStorage.getItem('user-token')
const headersFormData = {headers: {"Content-Type": `multipart/form-data;`, 'Authorization': `${token}`}}
const headers = {headers: { 'Authorization': `${token}`}}
const BASE_API = process.env.VUE_APP_BASE_API
const name = 'voting'

export default {

    create(formData){
        return axios.post(`${BASE_API}/${name}/create`,formData,headersFormData)
    },
    createRegisterVote(data){
        return axios.post(`${BASE_API}/${name}/createRegisterVote`,data)
    },
    uploadFile(formData){
        return axios.post(`${BASE_API}/${name}/uploadFile`,formData,headersFormData)
    },
    update(id,formData){
        return axios.post(`${BASE_API}/${name}/update/${id}`,formData,headersFormData)
    },
    downloadFile(data){
        return axios.post(`${BASE_API}/${name}/downloadFile`,data)
    },
    get(data){
        return axios.post(`${BASE_API}/${name}/`,data)
    },
    getBankParam(data){
        return axios.post(`${BASE_API}/${name}/getBankParam`,data)
    },
    delete(id){
        return axios.post(`${BASE_API}/${name}/destroy/${id}`,{},headers)
    }

} 



