<template>
  <div>
    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title class="headline red white--text" primary-title>
          <v-spacer />Atenção
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-5">Deseja sair, todo o processo será encerrado</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn text @click="openDialog = false">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn text to="/pages/voting">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <!-- =======================================================
                                Main
    ============================================================-->
    <v-container id="createEditvoting" fluid tag="section">
      <v-row justify="center">
        <v-col cols="10">
          <v-stepper v-model="stepper">
            <v-stepper-header>
              <v-divider></v-divider>
              <v-stepper-step :complete="stepperIndex > 1" step="1">Dados</v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step :complete="stepperIndex > 2" step="2">Post</v-stepper-step>

              <v-divider></v-divider>
            </v-stepper-header>

            <v-stepper-items class="stepper-items">
              <v-stepper-content step="1">
                <v-row justify="space-around">
                  <v-col cols="10">
                    <v-form>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" class="d-flex pa-0">
                            <v-col cols="12">
                              <v-text-field
                                v-model="description"
                                label="Descrição"
                                outlined
                                required
                              ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="6">
                              <v-select
                                v-model="bank"
                                outlined
                                :items="banks"
                                :rules="[v => !!v || 'Escolha um Banco']"
                                label="Banco"
                                required
                              ></v-select>
                            </v-col> -->
                          </v-col>
                          <!-- ========================================================
                                                      Date open
                          =============================================================-->
                          <v-col cols="12" class="d-flex pa-0">
                            <v-col cols="6" class="d-flex pa-0">
                              <v-col cols="6">
                                <v-menu
                                  v-model="menuPickerOpen"
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="computedDateOpenFormatted"
                                      label="Data de abertura"
                                      persistent-hint
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      outlined
                                      required
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dateOpen"
                                    locale="pt-br"
                                    no-title
                                    @input="menuPickerOpen = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="6">
                                <v-menu
                                  ref="menu"
                                  v-model="menuPickerOpenTime"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="timeOpen"
                                      label="Hora"
                                      readonly
                                      outlined
                                      required
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="menuPickerOpenTime"
                                    v-model="timeOpen"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menu.save(timeOpen)"
                                  ></v-time-picker>
                                </v-menu>
                              </v-col>
                            </v-col>

                            <!-- ========================================================
                                                      Date close
                            =============================================================-->
                            <v-col cols="6" class="d-flex pa-0">
                              <v-col cols="6">
                                <v-menu
                                  v-model="menuPickerClose"
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="computedDateCloseFormatted"
                                      label="Data de encerramento"
                                      persistent-hint
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      outlined
                                      required
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="dateClose"
                                    locale="pt-br"
                                    :min="dateOpen"
                                    no-title
                                    @input="menuPickerClose = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="6">
                                <v-menu
                                  ref="menu"
                                  v-model="menuPickerCloseTime"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="timeClose"
                                      label="Hora"
                                      readonly
                                      outlined
                                      required
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="menuPickerCloseTime"
                                    v-model="timeClose"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.menu.save(timeClose)"
                                  ></v-time-picker>
                                </v-menu>
                              </v-col>
                            </v-col>
                          </v-col>
                          <v-col class="d-flex align-center justify-center" cols="8">
                            <v-file-input v-model="file" label="Adicionar arquivos" outlined></v-file-input>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-col>
          
                </v-row>

                <v-container cols="12" class="d-flex justify-space-between px-0">
                  <v-btn text @click.prevent="cancel()">Cancelar</v-btn>
                  <v-btn color="primary" @click="next()">Próximo</v-btn>
                </v-container>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row>
                  <v-col cols="12">
                    <v-container cols="12" class="d-flex justify-center">
                      <template>
                        <div class="editor-wrapper">
                          <vue-editor
                            v-model="content"
                            :editorToolbar="editorToolbar"
                          ></vue-editor>
                        </div>
                      </template>
                    </v-container>
                  </v-col>
                </v-row>

                <v-container cols="12" class="d-flex justify-space-between px-0">
                  <v-btn text @click="stepper = 1">Voltar</v-btn>
                  <v-btn color="primary" @click="create()">Salvar</v-btn>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import categoryService from "../../../../service/categoryService";
import votingService from "../../../../service/votingService";
import { VueEditor } from "vue2-editor";

export default {
  name: "CreateEditVoting",
  components: {
    VueEditor
  },
  data: vm => ({
    description: "",
    file: {},
    menuPickerOpen: false,
    menuPickerOpenTime: false,
    menuPickerClose: false,
    menuPickerCloseTime: false,
    timeOpen: '00:00',
    editorToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
      ],
      [],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["link"],
      ["clean"] // remove formatting button
    ],
    timeClose: '12:00',
    dateOpen: new Date().toISOString().substr(0, 10),
    dateFormattedOpen: vm.formatDate(new Date().toISOString().substr(0, 10)),
    dateClose: new Date().toISOString().substr(0, 10),
    dateFormattedClose: vm.formatDate(new Date().toISOString().substr(0, 10)),
    overlay: false,
    content: "",
    fileName:"",
    openDialog: false,
    stepper: 1,
    stepperIndex: 1,
    // bank: null,
    // banks: [],
    snackbar: {
      text: "",
      active: false
    }
  }),
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${String(day).padStart(2, "0")}-${String(month).padStart(2, "0")}`;
    },
    cancel() {
      this.description || this.category || this.imgCard
        ? (this.openDialog = true)
        : this.$router.push({ path: "/pages/voting" });
    },
    getCategorys() {
      categoryService.get({}).then(res => {
        this.categoryes = res.data.map(category => {
          category.value = category.id;
          category.text = category.name;
          return category;
        });
      });
    },
    create() {
      const formData = new FormData();

     
      if(!this.file && !this.content){
        this.snackbar.text = "A votação deve conter pelo menos um arquivo ou texto.";
        this.snackbar.active = true;
        return
      }

      if (this.file && this.fileName !== this.file.name) {
        formData.append("file", this.file);
      }
      
      formData.append("description", this.description);
      formData.append("time_open", new Date(this.dateOpen +' '+ this.timeOpen));
      formData.append("time_close", new Date(this.dateClose +' '+ this.timeClose));
      formData.append("content", this.content);

      if (this.id) {
        this.overlay = true;
        votingService
          .update(this.id, formData)
          .then(res => {
            this.overlay = false;
            this.$router.push({ path: "/pages/voting" });
          })
          .catch(error => {
            this.overlay = false;
            this.snackbar.text = "Erro ao salvar a noticia.";
            this.snackbar.active = true;
          });
      } else {
        this.overlay = true;
        votingService
          .create(formData)
          .then(res => {
            this.overlay = false;
            this.$router.push({ path: "/pages/voting" });
          })
          .catch(error => {
            this.overlay = false;
            this.snackbar.text = "Erro ao salvar a noticia.";
            this.snackbar.active = true;
          });
      }
    },
    next() {
      if(!this.description){
          this.snackbar.text = "A descrição não pode ser vazia.";
          this.snackbar.active = true;
          return
      }

      this.stepper = 2;
    },
    formatPost(content) {
      let images = [];
      let regex = /<img src="([\w\W]+?)"/g;

      images = content.match(regex);

      if (images) {
        images.forEach(img => {
          content = String(content).replace(
            '<img src="',
            '<img class="img-post" src="'
          );
        });
      }

      return content;
    },
    formatTime(time){
      return time < 9 ? `0${time}`:time
    }
    // getBank(){
    //   votingService.getBankParam({}).then(res =>{
    //     this.banks = res.data.bank.map(bank => {
    //         return { text:bank.name, value: bank.id }
    //     })
        
    //   })
    // }
  },
  computed: {
    computedDateOpenFormatted() {
      return this.formatDate(this.dateOpen);
    },
    computedDateCloseFormatted() {
      return this.formatDate(this.dateClose);
    },
    editorContent: {
      get() {
        return this.formatPost(this.content);
      },
      set(updatedContent) {
        this.$emit("update:content", this.formatPost(updatedContent));
      }
    }
  },
  mounted() {
    this.id = parseInt(this.$router.history.current.params.id);
    // this.getBank()

    if (this.id) {
      this.overlay = true;
      votingService.get({ id: this.id }).then(res => {
        const data = res.data[0];
        const tzoffset = (new Date()).getTimezoneOffset() * 60000;
        this.description = data.description;
        this.category = data.id_category;
        this.timeOpen = `${this.formatTime(new Date(data.time_open).getHours())}:${this.formatTime(new Date(data.time_open).getMinutes())}`
        this.timeClose =  `${this.formatTime(new Date(data.time_close).getHours())}:${this.formatTime(new Date(data.time_close).getMinutes())}`
        this.dateOpen = new Date(new Date(data.time_open).getTime()-tzoffset).toISOString().substr(0, 10)
        this.dateClose= new Date(new Date(data.time_close).getTime()-tzoffset).toISOString().substr(0, 10)
        this.file = data.name_path ? new File([],data.name_path):{}
        this.fileName = data.name_path
        this.fileImgCardPreview = data.path_img_card;
        this.content = data.content;
        this.overlay = false;
      });
    }
  }
};
</script>


<style lang="sass" scoped>
.editor
  max-width: 100%
  width: 100%
  max-height: 28rem
  min-height: 28rem

.img-post
  max-width: 100%
  background-position: center

.ql-editor
  max-height: 25rem !important
  min-height: 25rem !important

.card-img
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  height: 100%
  width: 100%
</style>